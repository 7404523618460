<template>
  <div id="fave-container" class="md-card md-layout-item md-size-100 fave-card">
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-100">
        <div class="md-layout md-alignment-center-center">
          <p class="md-headline">{{ $t('bookmark.faveBookmarkPageTitle') }}</p>
        </div>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-100">
        <div class="md-layout md-alignment-center-center">
<!--          <a class="md-button md-raised download-button md-theme-default fave-it-btn"  onclick="alert('Drag me to the bookarks bar'); return false;"-->
<!--             href="javascript:(function(){-->
<!--         window.open('http://localhost:8080/user/bookmarklet?link='-->
<!--         +encodeURIComponent(window.location.href)+'&amp;title='+encodeURIComponent(document.title),'_blank','width=800, height=600')}())"-->
          <a class="md-button md-raised download-button md-theme-default fave-it-btn"  onclick="alert('Drag me to the bookarks bar'); return false;"
          href="javascript:(function(){
                     window.open('https://allmyfaves.com/user/bookmarklet?link='
                     +encodeURIComponent(window.location.href)+'&amp;title='+encodeURIComponent(document.title),'_blank','width=800, height=600')}())"
             title="Fave It" id="FaveItButton">
            <strong style="margin-left: 4px"><i class="fas fa-heart"></i>&nbsp; Fave It</strong><span></span>
          </a>
          <p class="drag-text"><i class="fas fa-arrow-left"></i>{{ $t('bookmarklet.drag') }} </p>
        </div>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-100">
        <div style="margin-left: 200px;" class="md-layout md-alignment-center-left md-size-100">
          <p class="bookmark-button-instructions"> <b class="bold-title-instruction">{{ $t('bookmarklet.install') }}</b>
          <ol>
            <li>{{ $t('bookmarklet.installex1') }}</li>
            <li>{{ $t('bookmarklet.installex2') }}</li>
            <li>{{ $t('bookmarklet.installex3') }}</li>
          </ol>
         </p>
        </div>
      </div>

      <div class="seperator"></div>
        <div class="md-layout-item md-small-size-100 md-size-100">
          <div class="md-layout md-alignment-center-center">
            <p class="md-headline">Chrome Extension</p>
          </div>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-100">
          <div style="margin-left: 200px;"  class="md-layout md-alignment-center-left md-size-100">
            <p class="bookmark-button-instructions">
              <b class="bold-title-instruction">
                To install the Chrome Extension:
              </b>
            <ol>
              <li>Click the <strong>blue button</strong> under and easily download the Chrome Extension</li>
              <li>Now, when you're browsing the web, simply click the AMF logo, in your extensions erea, to bookmark a
                website to your account</li>
              <li>Easily add a new folder of interest to your account. In addition, the extension allows you to
                import all your Chrome bookmarks. <br> In a simple one click, directly to your account. </li>
            </ol>
            <ol style="display: flex; align-items: center; justify-content: center;">
              <a href="https://chrome.google.com/webstore/detail/npklbnjanghgplhmccbfjapmmfdmajkn?hl=en-US&amp;hc=search&amp;hcp=main"
                onclick="_gaq.push(['_trackEvent', 'bookmark','chromebutton', 'click']);" target="_blank">
                <img src="//img1.minifav.net/images/signup-new/add-to-chrome .png" alt="Fave It" title="Fave It"
                  style="cursor: pointer; border-style: none;">
              </a>
            </ol>
            </p>
          </div>
        </div>

    </div>
  </div>
</template>
<script>

export default {
  name: 'FaveITButton',
  components: {},
  methods: {},
};
</script>
<style lang="scss">
@import '@/assets/scss/layout/favebutton.scss';
</style>
